$(function(){
    $('[data-moveTo], .toc_list a').on('click', function(){
        var speed = 500;
        var href= $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top - $('header').height();
        $('html, body').animate({scrollTop:position}, speed, 'swing');
        return false;
    });

    var target = location.hash;
    if (target) {
        var position = $(target).offset().top - $('header').height();
        $('html, body').animate({scrollTop:position}, 10, 'swing');
        return false;
    }
});
